#table-part-4 {
  border: 1px solid gray;
  width: 100%;
}

#table-part-4 tr td {
  /* border: 1px solid gray; */
}

#table-part-4 tr td:nth-child(1) {
  width: 20px;
}
#table-part-4 tr td:nth-child(2) {
  width: 40px;
}
#table-part-4 tr td:nth-child(3) {
  width: 40px;
}
#table-part-4 tr td:nth-child(5) {
  text-align: right;
}
#table-part-4 tr td:nth-child(6) {
  text-align: right;
}
#table-part-4 tr td:nth-child(7) {
  text-align: right;
}
#table-part-4 tr:first-child td, #table-part-4 tr:last-child td {
  background-color: #e5e5e5;
  font-weight: bold;
}
